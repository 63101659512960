
.teaser-group-element {
    display: flex;
    flex-wrap: wrap;
}

.teaser-group-element-item {
    width: 100%;
    background-color: $gray-f8;
    text-align: center;

    @include media-breakpoint-up(md) {
        width: calc(99.9% / 3);
    }
}


.teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    color: $gray-555;

    @include media-breakpoint-up(md) {
        &.teaser-even {
            flex-direction: column-reverse;
        }
    }

    &:hover {
        .teaser-image img {
            transform: scale(1.05);
        }

        .teaser-text {
            &::before {
                top: 1.375rem;
                @include media-breakpoint-up(xl) {
                    top: 1.75rem;
                }
            }
        }
    }

    &.teaser-even {
        &:hover {
            .teaser-text {
                &::before { 
                    top: auto;
                    bottom: 1.375rem;
                    @include media-breakpoint-up(xl) {
                        bottom: 1.75rem;
                    }
                }
            }
        }
    }

    /*AL: to prevent not clickable stickylinks on startpage*/
    .stretched-link::after {
        z-index: 0;
    }
}

.teaser-image {
    overflow: hidden;
    img {
        transition: all 1s;
    }
}

.teaser-text {
    position: relative;
    padding: 3.125rem 1.125rem 1.5rem;
    flex-grow: 2;

    &::before {
        position: absolute;
        display: block;
        left: 50%;
        top: 1.625rem;
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        margin-left: -0.375rem;
        border-top: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        transition: all 0.4s;

        @include media-breakpoint-up(xl) {
            top: 2rem;
            width: 1rem;
            height: 1rem;
            margin-left: -0.5rem
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 4.375rem 1.125rem 2.25rem;
    }

    .mb-0 {
        max-width: 22.5rem;
    }

    .h3 {
        @include media-breakpoint-up(xl) {
            font-size: 2.75rem;
        }
        span {
            font-size: 0.875rem;
            text-transform: uppercase;
            @include media-breakpoint-up(xl) {
                font-size: 1.375rem;
            }
        }
    }

    .teaser-even & {
        @include media-breakpoint-up(md) {
            padding: 1.5rem 1.125rem 3.125rem;

            &::before {
                top: auto;
                bottom: 1.625rem;
                transform: rotate(225deg);
            }
        }

        @include media-breakpoint-up(xl) {
            padding: 2.25rem 1.125rem 4.375rem;

            &::before {
                bottom: 2rem;
            }
        }

    }


}
